import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  MoreIcon,
  AddIcon,
  HelpIcon,
  DropdownMenuItems,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
    
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Support'),
      href: 'https://t.me/jp_token',
      icon: HelpIcon,
      fillIcon: HelpIcon,
      showItemsOnMobile: false,
      image: '/images/decorations/pe2.png',
      items: [
     
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('List Your Token'),
      href: 'https://jptoken.info/addcoin/',
      icon: AddIcon,
      fillIcon: AddIcon,
      showItemsOnMobile: false,
      items: [
      
      ],
    },
    {
      label: '',
      href: '/info',
      icon: MoreIcon,
      hideSubNav: true,
      items: [
        {
          label: t('Website'),
          href: 'https://jp-token.com',
        },
        {
          label: t('Twitter'),
          href: 'https://twitter.com/JPTOKEN_',
   
        },

        {
          type: DropdownMenuItemType.DIVIDER,
        },
        {
          label: t('News'),
          href: 'https://cryptonewsjp.xyz/',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('Whitepaper'),
          href: 'https://jptoken.info/jp/jp-whitepaper.pdf',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
